export const isFromValid = ({ formData, requiredFields }) => {
  let isValid = true;
  requiredFields?.forEach((field) => {
    if (formData[field] == null || formData[field].length <= 0) {
      isValid = false;
    }
  });
  return isValid;
};

/**
 * Validate Field value is valid or not
 * @param {String} value
 * @returns
 */
export const isValidate = (validateForm, value) => {
  if (value != null) {
    return validateForm && value.length <= 0;
  }
  return validateForm && true;
};
