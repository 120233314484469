import { getTokensFromStorage } from '../Auth/AuthToken';

let header = {
  'Accept': 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};
// Merge token in headers if available
const { accessToken } = getTokensFromStorage();
if (accessToken) {
  let accessTokenData = {
    Authorization: `Bearer ${accessToken}`,
  };
  header = { ...header, ...accessTokenData };
}

/**

 * Used to Handle Error Response, axios error and all other type of errors

 * @param {*} error api error

 */
const handleApiError = (error) => {
  let errorResponse;
  if (error.response && error.response.data) {
    // handle error responses in valid format
    // JSON stringify if you need the json and use it later
    errorResponse = JSON.stringify(error.response.data);
  } else if (error.request) {
    // TO Handle the default error response for Network failure or 404 etc.,
    errorResponse =
    error.request.message || error.request.statusText || error.message;
  } else {

    errorResponse = error.message;

  }
  // Check if there is aborted in error message then it will not throw the exception
  if (!errorResponse.match('aborted')) {

    throw new Error(errorResponse);
  }
};
/**
 * To Generate Api Request
 * @param endpoint
 * @param headers
 * @param method
 * @param data
 * @returns {Promise<{response: null, error}|{response: any, error: null}>}
 */
export const callApi = async (endpoint, method, data , headers) => {
  try {
    // Merge Additional Header
    header = { ...headers, ...header };
    // Call Api with fetch
    const url = process.env.BASE_URL + endpoint;
    const response = await fetch(url, {
      method,
      body: data,
      headers: header,
    });
    const json = await response.json();
    return json;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};
