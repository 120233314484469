/**
 * Parse Location Hirearchy for Lookup Tree
 * @param {Object} arr
 * @returns {Object} Array
 */
export const parseLocationHirearchyArray = (arr) => {
  // Loop through each object in the array
  for (let i = 0; i < arr.length; i += 1) {
    const obj = arr[i];

    // Replace "Location" property with "Name" property
    if (obj.Location) {
      obj.Name = obj.Location;

      delete obj.Location;
    }

    // Recursively replace "SubLocationX" properties with "Location" property
    for (const prop in obj) {
      // obj.ID = `${prop}-${subObj.Name}-${i}`;

      if (prop.startsWith("SubLocation")) {
        const subLocObj = obj[prop];
        delete obj[prop];
        if (Array.isArray(subLocObj)) {
          // Handle arrays of sub-locations
          for (let j = 0; j < subLocObj.length; j += 1) {
            const subObj = subLocObj[j];
            if (subObj.Name) {
              subObj.Location = subObj.Name;
              subObj.ID = `${prop}-${subObj.Name}-${j}${i}`;
              delete subObj.Name;
            }
            parseLocationHirearchyArray([subObj]); // Recursively call parseLocationHirearchyArray on sub-objects
          }
          obj.Location = subLocObj; // Set "Location" property to array of sub-objects
        } else {
          // Handle single sub-location object
          if (subLocObj.Name) {
            subLocObj.Location = subLocObj.Name;
            subObj.ID = `${prop}-${subObj.Name}-${i}`;
            delete subLocObj.Name;
          }
          parseLocationHirearchyArray([subLocObj]); // Recursively call parseLocationHirearchyArray on sub-object
          obj.Location = subLocObj; // Set "Location" property to modified sub-object
        }
      }
    }
  }
  return arr;
};
/**
 * formatDate
 * @param  string
 * @returns string
 */
export const formatDate = (dateStr) => {
  // Create a new Date object from the string
  const date = new Date(dateStr);

  // Get the day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Format the date
  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};
/**
 * convertToLocalDate
 * @param  string
 * @returns string
 */
export const convertToLocalDate = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  // Check if the date string ends with 'Z', if not, append it
  if (!dateStr.endsWith("Z")) {
    dateStr += "Z"; // Append 'Z' to signify UTC
  }

  // Create a Date object from the UTC date string
  const date = new Date(dateStr);

  // Extract local date and time parts
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Return the formatted date as DD-MM-YYYY
  return `${month}-${day}-${year}`;
};

/**
 * formatDateStandard
 * @param  string
 * @returns string
 */
export const formatDateStandard = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);

  // Extract local date and time parts
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Return the formatted date as DD-MM-YYYY
  return `${month}-${day}-${year}`;
};

/**
 * extractNumberFromString
 * @param  string
 * @returns int
 */
export const extractNumberFromString = (str) => {
  const match = str.match(/\d+/); // Match one or more digits
  return match ? match[0] : null; // Return the matched number or null if no match
};

/**
 * isComponentUpdate
 * @param prevProps 
 * @param nextProps
 */
export const isComponentUpdate = (prevProps, nextProps) => {
  // Only re-render when shouldUpdate changes
  return prevProps.shouldUpdate === nextProps.shouldUpdate;
};
