// #region Service worker registration

/**
 * Sync Process activities
 * @returns {Array[]} returns List of activities for sync process and sync interval
 */
const syncProcessActivities = () =>
  // List of activies for sync process and sync interval.
  [
    // [
    //   EVENTS_DATA_TYPES.FACILITY,
    //   EQUIPMENT_LOOKUPS,
    //   SYNC_PROCESS_INTERVAL.EQUIPMENT_LOOKUPS ?? process.env.SYNC_INTERVAL,
    // ],
    // #endregion  Application Configs
  ];

// #endregion

export default syncProcessActivities;
